import * as React from "react"
import Helmet from "react-helmet"
import ShareLogoImage from "../../images/skideo/light_purple_square.drawio.png";

const HeaderMeta = (props) =>{
    return (
        <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{props.title || "Welcome to Skideo"}</title>
        <meta name="title" content={props.title || "Welcome to Skideo"} />
        <meta name="description" content={props.description ||"We are providing limitless education to unlock unlimited potentials."} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://website.skideo.tv/" />
        <meta property="og:title" content="Welcome to Skideo" />
        <meta property="og:description" content="We are providing limitless education to unlock unlimited potentials." />
        <meta property="og:image" content={ShareLogoImage} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://website.skideo.tv/" />
        <meta property="twitter:title" content="Welcome to Skideo" />
        <meta property="twitter:description" content="We are providing limitless education to unlock unlimited potentials." />
        <meta property="twitter:image" content={ShareLogoImage} />

      </Helmet>
      
    )
}

export default HeaderMeta